import React, {useState} from "react";
import Layout from "../components/layout2";
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input/input';


const QuotePage = () => {
  const [formData, setFormData] = useState({
    name: '',
    phoneNumber: '',
    email: '',
    leaks: '',
    age: '',
    timeline: '',
    zipCode: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  };

  const handlePhoneChange = (phone) => {
    setFormData({ ...formData, phoneNumber: phone})
  }

  return (
    <Layout>
      <div className="flex justify-center items-center px-8 mt-32">
        <div className="px-8 pb-8 w-full md:w-auto max-w-5xl shadow-lg rounded-lg bg-white border border-neutral-50">
          <form
            name="quote"
            method="post"
            action="/thanks"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            className="space-y-6"
          >
            <input type="hidden" name="form-name" value="quote" />
            <p hidden>
              <label>
                Don’t fill this out: 
                <input name="bot-field" onChange={handleChange} className="p-2 mt-1 block w-full rounded-md border-gray-300 shadow-sm" />
              </label>
            </p>
            <h1 className="text-center font-display text-display-lg text-primary-600">
              Contact Information
            </h1>
            <div className="flex flex-col md:flex-row -mx-3 mb-4">
              <div className="flex-1 px-3 mb-4 md:mb-0">
                <label className="block text-neutral-700 text-display-xs font-small mb-1" htmlFor="name">Name <span className="text-primary-600">*</span></label>
                <input id="name" name="name" type="text" className="form-input w-full border border-primary-300 rounded text-gray-800 py-2 px-4" placeholder="Enter your name" value={formData.name} onChange={handleChange} required />
              </div>
              <div className="flex-1 px-3 mb-4 md:mb-0">
                <label className="block text-neutral-700 text-display-xs font-small mb-1" htmlFor="phoneNumber">Phone Number <span className="text-primary-600">*</span></label>
                <PhoneInput
                  name="phoneNumber"
                  country="US"
                  placeholder="Enter phone number"
                  value={formData.phoneNumber}
                  onChange={handlePhoneChange}
                  className="form-input w-full border border-primary-300 rounded text-gray-800 py-2 px-4"
                  international={false}
                  withCountryCallingCode={true}
                  required
                />
              </div>
              <div className="flex-1 px-3">
                <label className="block text-neutral-700 text-display-xs font-small mb-1" htmlFor="email">Email <span className="text-primary-600">*</span></label>
                <input id="email" name="email" type="email" className="form-input w-full border border-primary-300 rounded text-gray-800 py-2 px-4" placeholder="Enter your email address" value={formData.email} onChange={handleChange} required />
              </div>
            </div>
            <h1 className="text-center font-display text-display-lg text-primary-600">
              Roof Information
            </h1>
            <div className="flex flex-col md:flex-row -mx-3 mb-4">
              <div className="flex-1 px-3 mb-4 md:mb-0">
                <label className="block text-neutral-700 text-display-xs font-small mb-1" htmlFor="leaks">Leaks <span className="text-primary-600">*</span></label>
                <select 
                  id="leaks" 
                  name="leaks" 
                  value={formData.leaks || ''} 
                  onChange={handleChange} 
                  className="form-select w-full border border-primary-300 rounded text-gray-800 py-2 px-4"
                  required
                >
                  <option value="">Choose an option</option>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                  <option value="i_don't_know">I don't know</option>
                </select>
              </div>
              <div className="flex-1 px-3 mb-4 md:mb-0">
                <label className="block text-neutral-700 text-display-xs font-small mb-1" htmlFor="age">Roof Age <span className="text-primary-600">*</span></label>
                <select 
                  id="age" 
                  name="age" 
                  value={formData.age || ''} 
                  onChange={handleChange} 
                  className="form-select w-full border border-primary-300 rounded text-gray-800 py-2 px-4"
                  required
                >
                  <option value="">Choose an option</option>
                  <option value="0_-_5_years_old">0 - 5 years old</option>
                  <option value="5_-_10_years_old">5 - 10 years old</option>
                  <option value="10_-_15_years_old">10 - 15 years old</option>
                  <option value="15_-_20_years_old">15 - 20 years old</option>
                  <option value="20_+_years_old">20+ years old</option>
                </select>
              </div>
              <div className="flex-1 px-3 mb-4 md:mb-0">
                <label className="block text-neutral-700 text-display-xs font-small mb-1" htmlFor="timeline">Timeline <span className="text-primary-600">*</span></label>
                <select 
                  id="timeline" 
                  name="timeline" 
                  value={formData.timeline || ''} 
                  onChange={handleChange} 
                  className="form-select w-full border border-primary-300 rounded text-gray-800 py-2 px-4"
                  required
                >
                  <option value="">Choose an option</option>
                  <option value="as_soon_as_possible">As soon as possible</option>
                  <option value="within_the_next_month">Within the next month</option>
                  <option value="within_the_next_6_months">Within the next 6 months</option>
                  <option value="i'm_just_looking">I'm just looking</option>
                </select>
              </div>
              <div className="flex-1 px-3">
                <label className="block text-neutral-700 text-display-xs font-small mb-1" htmlFor="zipCode">Zip Code <span className="text-primary-600">*</span></label>
                <input id="zipCode" name="zipCode" type="text" className="form-input w-full border border-primary-300 rounded text-gray-800 py-2 px-4" placeholder="Enter your zip code" value={formData.zipCode} onChange={handleChange} required />
              </div>
            </div>
            {/* Navigation Buttons */}
            <div className="flex flex-col space-y-4">
              <button type="submit" className="bg-primary-600 hover:bg-primary-900 text-white font-semibold py-2 px-4 rounded-lg transition duration-300">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </Layout>
  );
};

export default QuotePage;
